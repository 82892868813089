import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { Options } from './Elements/Options';
import { Grid, LoadingSpinner, Spacer, Text } from 'dss-ui-library';
import { ContactFormEvents } from '../../../interfaces/tracking';
import { pushDataLayer } from '../../../utils/dataLayer';
import { FormsWrapper } from '../FormsWrapper';
import { hasActionCode, ACTION_CODES_PK } from '@ncs-frontend-monorepo/utils';

export enum ContactView {
  NewCustomer = 'new-customer',
  ExistingCustomer = 'existing-customer',
}

const ExistingCustomer = dynamic(() => import('./Views/ExistingCustomer'), {
  ssr: false,
  loading: () => <LoadingSpinner theme="blue" />,
});

const NewCustomer = dynamic(() => import('./Views/NewCustomer'), {
  ssr: false,
  loading: () => <LoadingSpinner theme="blue" />,
});

export const Contact: React.FC = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [viewType, setViewType] = useState<ContactView>(
    hasActionCode(ACTION_CODES_PK.CONTACT_UPLOAD_FORM)
      ? ContactView.ExistingCustomer
      : null,
  );
  const contentBlock =
    viewType === ContactView.NewCustomer
      ? 'contact-form-new-success'
      : 'contact-form-existing-success';
  const layerEvent: ContactFormEvents = {
    event: 'contact-form-submission',
    contactType: viewType === ContactView.ExistingCustomer ? 'existing' : 'new',
  };

  const handleSuccess = () => {
    const layerEvent: ContactFormEvents = {
      event: 'contact-form-submit',
      contactType:
        viewType === ContactView.ExistingCustomer ? 'existing' : 'new',
    };
    pushDataLayer(layerEvent);
    setShowSuccess(true);
  };

  return (
    <FormsWrapper
      e2e={`contact-form-${viewType}`}
      isSubmitted={showSuccess}
      contentBlock={contentBlock}
      layerEvent={layerEvent}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Text appearance="t2" color="blue" element="h2">
              Wie können wir dir helfen?
            </Text>
            <Spacer t={2} b={3}>
              <Options currentView={viewType} setViewType={setViewType} />
            </Spacer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {viewType === ContactView.ExistingCustomer && (
              <ExistingCustomer handleSuccess={handleSuccess} />
            )}
            {viewType === ContactView.NewCustomer && (
              <NewCustomer handleSuccess={handleSuccess} />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormsWrapper>
  );
};
