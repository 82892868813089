import React, { useEffect } from 'react';
import { Textarea as NCTextArea, TextareaProps } from 'dss-ui-library';
import { Field, FieldProps } from 'formik';
import objectPath from 'object-path';

const CustomTextArea = ({
  field,
  form: { errors, touched, setFieldTouched },
  ...textAreaProps
}: FieldProps & TextareaProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);
  return (
    <NCTextArea
      {...field}
      {...textAreaProps}
      hideMaxChars={errorMessage}
      errorMessage={_touched && errorMessage ? errorMessage : undefined}
    />
  );
};

const Textarea: React.FC<TextareaProps> = ({ ...props }) => (
  <Field component={CustomTextArea} {...props} />
);

export { Textarea };
