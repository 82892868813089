import React, { useEffect } from 'react';
import { Dropdown as NCDropdown, DropdownProps } from 'dss-ui-library';
import objectPath from 'object-path';

import { Field, FieldProps } from 'formik';

const CustomDropdown = ({
  field,
  form: { errors, touched, setFieldTouched },
  ...textFieldProps
}: FieldProps & DropdownProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);
  const selectedValue = textFieldProps.options
    .filter((option) => option.value === field.value)
    .shift();

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);

  return (
    <div data-cs-mask>
      <NCDropdown
        {...textFieldProps}
        value={selectedValue}
        name={field.name}
        onChange={(option) => {
          field.onChange({
            target: { name: field.name, value: option.value },
          });
        }}
        onBlur={field.onBlur}
        errorMessage={_touched && errorMessage ? errorMessage : undefined}
      />
    </div>
  );
};

const Dropdown: React.FC<DropdownProps> = ({ ...props }) => (
  <Field component={CustomDropdown} {...props} />
);

export { Dropdown };
