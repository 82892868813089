import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import objectPath from 'object-path';
import { TextField as NCTextField, TextFieldProps } from 'dss-ui-library';
import { Field, FieldProps, FieldConfig, useFormikContext } from 'formik';

const CustomTextField = ({
  field,
  form: { errors, touched, setFieldTouched },
  ...textFieldProps
}: FieldProps & TextFieldProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);

  return (
    <div data-cs-mask>
      <NCTextField
        {...field}
        {...textFieldProps}
        errorMessage={_touched && errorMessage ? errorMessage : undefined}
      />
    </div>
  );
};

const TextField: React.FC<FieldConfig & TextFieldProps> = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();

  const onBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props?.onBlur) props.onBlur(e as SyntheticEvent<any>);

      if (!e.target || typeof e.target.value != 'string') return;

      // if trimmed value differs from original value then update formik state
      const trimmedValue = e.target.value.trim();
      if (trimmedValue && trimmedValue !== e.target.value) {
        setFieldValue(props.name, trimmedValue);
      }
    },
    [props.onBlur],
  );

  return <Field component={CustomTextField} {...props} onBlur={onBlur} />;
};

export { TextField };
