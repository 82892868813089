import React, { useRef, useEffect, useState } from 'react';
import { TextField as NCTextField, TextFieldProps } from 'dss-ui-library';
import { Field, FieldProps } from 'formik';
import objectPath from 'object-path';
import { createTextMaskInputElement } from 'text-mask-core';

const birthdayMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

const CustomDate = ({
  field,
  form: { errors, touched, setFieldTouched },
  ...textFieldProps
}: FieldProps & TextFieldProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  const inputRef = useRef(null);
  const [textMask, setTextMask] = useState(null);

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      const textMaskInputElement = createTextMaskInputElement({
        inputElement: inputRef.current,
        mask: birthdayMask,
        guide: false,
        keepCharPositions: true,
      });
      setTextMask(textMaskInputElement);
    }
  }, [inputRef.current]);

  return (
    <div data-cs-mask>
      <NCTextField
        {...field}
        {...textFieldProps}
        inputRef={inputRef}
        onChange={(e) => {
          textMask.update();
          field.onChange(e);
        }}
        errorMessage={_touched && errorMessage ? errorMessage : undefined}
      />
    </div>
  );
};

const Date: React.FC<TextFieldProps> = ({ ...props }) => (
  <Field component={CustomDate} {...props} />
);

export { Date };
