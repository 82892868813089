import React, { useEffect } from 'react';
import { Checkbox as NCCheckbox, CheckboxProps } from 'dss-ui-library';
import { Field, FieldProps } from 'formik';
import objectPath from 'object-path';

const CustomCheckbox = ({
  field,
  form: { errors, touched, setFieldTouched },
  ...checkboxProps
}: FieldProps & CheckboxProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);

  return (
    <NCCheckbox
      {...field}
      {...checkboxProps}
      checked={field.value}
      onChange={(_) => {
        field.onChange({
          target: { name: field.name, value: !field.value },
        });
      }}
      errorMessage={_touched && errorMessage ? errorMessage : undefined}
    />
  );
};

const Checkbox: React.FC<CheckboxProps> = ({ ...props }) => (
  <Field component={CustomCheckbox} {...props} />
);

export { Checkbox };
