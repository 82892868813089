import { CancellationRequest } from '../components/Forms/Cancellation/cancellationFormModel';
import { ContactView } from '../components/Forms/Contact';
import { ContactNewCustomerRequest } from '../components/Forms/Contact/Views/NewCustomer/model';
import { OrderRequest, OrderResponse } from '../interfaces';
import {
  ContractSummary,
  ContractSummaryByBasketIdParams,
  ContractSummaryParams,
} from '../interfaces/contractSummary';
import {
  correlationIdHeader,
  fetcher,
  fetcherBoolean,
  fetcherText,
  getCorrelationId,
  getEnv,
  Mandant,
  removeCorrelationId,
} from '@ncs-frontend-monorepo/utils';
import { ResellerContactRequest } from '../components/Forms/ResellerContact/model';
import {
  ProductId,
  ImmutableBasket,
  BasketPosition,
  BasketProduct,
  PODInfo,
} from '@ncs-frontend-monorepo/order';
import { Technology } from '@ncs-frontend-monorepo/availability';

export const orderServiceURL = (): string => getEnv().ORDER_SERVICE_URL;
export const homeIdCheckURL = (): string =>
  `${getEnv().ORDER_SERVICE_URL}/check-home-id`;
export const dssApiURL = (): string => getEnv().PK.DSS_API_URL;

export async function getContractSummary(
  params: ContractSummaryParams,
): Promise<ContractSummary> {
  return await fetcher(`${getEnv().ORDER_SERVICE_URL}/contract-summary`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify(params),
  });
}

export async function getContractSummaryByBasketId({
  params,
  idToken,
}: {
  params: ContractSummaryByBasketIdParams;
  idToken?: string;
}): Promise<ContractSummary> {
  const fileId = await fetcherText(
    `${dssApiURL()}/ftth/basket/contract-summary`,
    {
      method: 'POST',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        [correlationIdHeader]: getCorrelationId(),
      },
      body: JSON.stringify(params),
    },
  );
  return {
    fileId,
  };
}

export interface GetProductProps {
  templateId: ProductId;
  promotionId?: string;
  withoutRuntime?: boolean;
  maxDownload?: number;
  technology?: Technology;
  zipCode?: string;
  mandant?: Mandant;
  pubId?: string;
}

export interface GetDevicesProps {
  templateId: ProductId;
}

export async function postBasket(
  order: BasketProduct,
): Promise<BasketPosition[]> {
  return await fetcher(`${orderServiceURL()}/basket`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify(order),
  });
}

export async function postImmutableBasket(
  basket: BasketProduct,
  podInfo: PODInfo,
): Promise<ImmutableBasket> {
  return await fetcher(`${dssApiURL()}/ftth/basket`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify({
      templateId: basket.id,
      device: basket.device,
      ontDevice: basket?.ontDevice,
      installationService: basket.installationService || 'none',
      houseSerialNumber: basket.installationAddress.hausLfdnr,
      propertyOwnerRole: podInfo?.propertyOwnerRole,
    }),
  });
}

export async function postOrder(order: OrderRequest): Promise<OrderResponse> {
  const response = await fetcher(`${orderServiceURL()}/orders`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify(order),
  });
  removeCorrelationId();
  return response;
}

export async function postCancellation(
  data: CancellationRequest,
): Promise<boolean> {
  await fetcherBoolean(
    `${orderServiceURL()}/support/cancellationform/anonymous`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        [correlationIdHeader]: getCorrelationId(),
      },
      body: JSON.stringify(data),
    },
  );
  return true;
}

export async function postContact(
  data: ContactNewCustomerRequest,
  type: ContactView,
): Promise<boolean> {
  const url =
    type === ContactView.ExistingCustomer
      ? `${orderServiceURL()}/support/website/contact/customer/existing`
      : `${orderServiceURL()}/support/website/contact/customer/new`;
  await fetcherBoolean(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [correlationIdHeader]: getCorrelationId(),
    },
    body: JSON.stringify(data),
  });

  return true;
}

export async function postResellerContact(
  data: ResellerContactRequest,
): Promise<boolean> {
  await fetcherBoolean(
    `${orderServiceURL()}/support/website/contact/reseller/new`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        [correlationIdHeader]: getCorrelationId(),
      },
      body: JSON.stringify(data),
    },
  );
  return true;
}
