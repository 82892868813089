import React, { useEffect, useRef, useState } from 'react';
import { ContactFormEvents } from '../../../interfaces/tracking';
import { Expandable, LoadingSpinner } from 'dss-ui-library';
import { pushDataLayer } from '../../../utils/dataLayer';
import { contentApiURL } from '../../../utils';
import useSWR from 'swr';
import {
  DynamicContent,
  DynamicContentProps,
} from '@ncs-frontend-monorepo/content-library';
import styles from './FormsWrapper.module.scss';
import cx from 'classnames';
import { fetcher, useScrollTo } from '@ncs-frontend-monorepo/utils';

interface FormsWrapperProps {
  isSubmitted: boolean;
  children: React.ReactNode;
  contentBlock: string;
  e2e: string;
  layerEvent?: ContactFormEvents;
}

export const FormsWrapper: React.FC<FormsWrapperProps> = ({
  isSubmitted,
  children,
  contentBlock,
  e2e,
  layerEvent,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const contentRef = useRef<HTMLElement>(null);
  const contactSuccess = `${contentApiURL()}${contentBlock}`;
  const { data } = useSWR<DynamicContentProps>(contactSuccess, fetcher);

  const scrollIntoView = useScrollTo(contentRef);

  useEffect(() => {
    if (isSubmitted) {
      scrollIntoView();

      if (layerEvent) {
        pushDataLayer(layerEvent);
      }

      const timer = setTimeout(() => {
        setShowSuccess(true);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isSubmitted]);

  useEffect(() => {
    showSuccess && scrollIntoView();
  }, [showSuccess]);

  return (
    <section ref={contentRef}>
      <Expandable e2e={e2e} expanded={!isSubmitted}>
        <div
          className={cx(styles.formWrapper, {
            [styles.isSubmitted]: isSubmitted,
          })}
        >
          {children}
        </div>
      </Expandable>
      <Expandable e2e={`${e2e}-success`} expanded={showSuccess}>
        <div
          className={cx(styles.successWrapper, {
            [styles.isSubmitted]: showSuccess,
          })}
        >
          {data ? (
            <DynamicContent content={data.content} />
          ) : (
            <LoadingSpinner autoPositioning theme="blue" />
          )}
        </div>
      </Expandable>
    </section>
  );
};
