import React, { useEffect } from 'react';
import { TextFieldProps, ErrorMessage } from 'dss-ui-library';
import { Field, FieldProps } from 'formik';
import objectPath from 'object-path';

const CustomTextField = ({
  field,
  form: { errors, touched, setFieldTouched },
}: FieldProps & TextFieldProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);

  return (
    <>
      <input type="hidden" name={field.name} value={field.value} />
      {_touched && errorMessage && (
        <ErrorMessage e2e={field.name}>{errorMessage}</ErrorMessage>
      )}
    </>
  );
};

const Hidden: React.FC<TextFieldProps> = ({ ...props }) => (
  <Field component={CustomTextField} {...props} />
);

export { Hidden };
