import React from 'react';
import { OutputField as NCOutputField, OutputFieldProps } from 'dss-ui-library';
import { Field, FieldProps } from 'formik';

interface WithName {
  name: string;
}

const CustomOutputField: React.FC<FieldProps & OutputFieldProps> = ({
  field,
  ...outputFieldProps
}) => (
  <div data-cs-mask>
    <NCOutputField
      label={outputFieldProps.label}
      e2e={outputFieldProps.e2e}
      textOpacity={outputFieldProps.textOpacity}
    >
      {field.value}
    </NCOutputField>
  </div>
);

const OutputField: React.FC<OutputFieldProps & WithName> = ({ ...props }) => {
  return <Field component={CustomOutputField} {...props} />;
};

export { OutputField };
