import React from 'react';
import objectPath from 'object-path';
import {
  DatePicker as NCDatepicker,
  DatePickerProps,
  useBreakpoints,
} from 'dss-ui-library';
import { Field, FieldProps, FieldConfig } from 'formik';

const CustomDatePickerField = ({
  field,
  form,
  ...datepickerFieldProps
}: FieldProps & DatePickerProps) => {
  const { isXS } = useBreakpoints();
  const { errors, touched } = form;
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);
  return (
    <NCDatepicker
      {...field}
      {...datepickerFieldProps}
      withPortal={isXS}
      onChange={(d) => form.setFieldValue(field.name, d, true)}
      errorMessage={_touched && errorMessage ? errorMessage : undefined}
    />
  );
};

const Datepicker: React.FC<FieldConfig & DatePickerProps> = ({ ...props }) => {
  return <Field component={CustomDatePickerField} {...props} />;
};

export { Datepicker };
