import React, { useEffect, useState } from 'react';
import objectPath from 'object-path';
import {
  FileUpload as NCFileUpload,
  FileUploadProps,
  UploadedFileProps,
  ErrorMessage,
  Spacer,
} from 'dss-ui-library';
import { FieldArray, FieldArrayConfig, FieldArrayRenderProps } from 'formik';

const CustomFileUpload = ({
  name,
  push,
  remove,
  form: { errors, touched, setFieldTouched },
  ...fileUploadProps
}: FieldArrayRenderProps & FileUploadProps) => {
  const errorMessage = objectPath.get(errors, name);
  const _touched = objectPath.get(touched, name);
  const [droppedFiles, setDroppedFiles] = useState<UploadedFileProps[]>([]);

  useEffect(() => {
    setFieldTouched(name, false);
  }, []);

  const handleFileUpload = (
    f: UploadedFileProps,
    setProgress: (progress: number, error: string) => void,
  ) => {
    fileUploadProps.onFileUpload(f, (p, e) => {
      if (p === 100 && !e) {
        if (!droppedFiles.find((_f) => f.uid === _f.uid)) {
          setDroppedFiles((files) => [...files, f]);
          push(f.uid);
        }
      }
      setProgress(p, e);
    });
  };

  const handleFileDelete = (f: UploadedFileProps): Promise<void> => {
    if (!f.errorText) {
      return fileUploadProps
        .onFileDelete(f)
        .then(() => {
          const index = droppedFiles.findIndex(({ uid }) => uid === f.uid);
          if (index >= 0) {
            setDroppedFiles((files) =>
              files.filter((file) => file.uid !== f.uid),
            );
            remove(index);
          }
        })
        .catch((e) => console.error(e));
    }
    return new Promise((r) => r());
  };

  return (
    <>
      <NCFileUpload
        {...fileUploadProps}
        subtitle={fileUploadProps.subtitle}
        onFileUpload={handleFileUpload}
        onFileDelete={handleFileDelete}
        name={name}
      />
      {_touched && errorMessage && (
        <>
          <Spacer t={1} block />
          <ErrorMessage e2e={`error-upload-${fileUploadProps.e2e}`}>
            {errorMessage}
          </ErrorMessage>
        </>
      )}
    </>
  );
};

const FileUpload: React.FC<FieldArrayConfig & FileUploadProps> = ({
  ...props
}) => (
  <FieldArray
    render={(helpers) => <CustomFileUpload {...props} {...helpers} />}
    {...props}
  />
);

export { FileUpload };
