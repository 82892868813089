import React from 'react';
import { Grid, IconCrown, IconHands, Radio, Text } from 'dss-ui-library';
import { ContactView } from '../../index';
import cx from 'classnames';
import styles from './Options.module.scss';

interface OptionsProps {
  currentView: ContactView;
  setViewType: (viewType: ContactView) => void;
}

export const Options: React.FC<OptionsProps> = ({
  currentView,
  setViewType,
}) => {
  const handleOptionSelect = (type: ContactView) => {
    setViewType(type);
  };
  return (
    <Grid fullWidth>
      <Grid.Row>
        <Grid.Column xs={6} m={4} l={3}>
          <div
            className={cx(styles.optionsWrapper, {
              [styles.selected]: currentView === ContactView.ExistingCustomer,
            })}
            onClick={() => handleOptionSelect(ContactView.ExistingCustomer)}
          >
            <div className={styles.iconWrapper}>
              <IconCrown
                color={
                  currentView === ContactView.ExistingCustomer
                    ? 'white'
                    : 'blue'
                }
                className={cx(styles.iconOption)}
                height={66}
                width={66}
              />
            </div>
            <Radio
              label={<Text appearance="t5_2">Du bist schon Kunde.</Text>}
              name="existing-customer"
              value="exisiting-customer"
              e2e="contact-existing-customer"
              background={
                currentView === ContactView.ExistingCustomer ? 'blue' : 'white'
              }
              checked={currentView === ContactView.ExistingCustomer}
            />
          </div>
        </Grid.Column>
        <Grid.Column xs={6} m={4} l={3}>
          <div
            className={cx(styles.optionsWrapper, {
              [styles.selected]: currentView === ContactView.NewCustomer,
            })}
            onClick={() => handleOptionSelect(ContactView.NewCustomer)}
          >
            <div className={styles.iconWrapper}>
              <IconHands
                color={
                  currentView === ContactView.NewCustomer ? 'white' : 'blue'
                }
                className={cx(styles.iconOption)}
                height={66}
                width={66}
              />
            </div>
            <Radio
              label={
                <Text appearance="t5_2">
                  Du bist noch kein Kunde, möchtest es aber werden.
                </Text>
              }
              name="new-customer"
              value="new-customer"
              e2e="contact-new-customer"
              background={
                currentView === ContactView.NewCustomer ? 'blue' : 'white'
              }
              checked={currentView === ContactView.NewCustomer}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
