import { AutoSuggest } from './AutoSuggest';
import { TextField } from './TextField';
import { Checkbox } from './Checkbox';
import { Dropdown } from './Dropdown';
import { Date } from './Date';
import { Radio } from './Radio';
import { OutputField } from './OutputField';
import { Iban } from './Iban';
import { Datepicker } from './Datepicker';
import { FileUpload } from './FileUpload';
import { Textarea } from './Textarea';
import { Hidden } from './Hidden';

const FormikFields = {
  AutoSuggest,
  TextField,
  Checkbox,
  Dropdown,
  Date,
  Radio,
  OutputField,
  Iban,
  Datepicker,
  FileUpload,
  Textarea,
  Hidden,
};

export { FormikFields };
