import React, { useEffect } from 'react';
import { Radio as NCRadio, RadioProps } from 'dss-ui-library';
import { Field, FieldProps, FieldConfig } from 'formik';
import objectPath from 'object-path';

const CustomRadio = ({
  field,
  checked,
  form: { errors, touched, setFieldTouched },
  ...radioProps
}: FieldProps & RadioProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);

  return (
    <NCRadio
      {...field}
      {...radioProps}
      centered={radioProps.centered}
      checked={field.value === radioProps.value || checked}
      errorMessage={_touched && errorMessage ? errorMessage : undefined}
      onChange={(_) => {
        field.onChange({
          target: { name: field.name, value: radioProps.value },
        });
      }}
      onBlur={() => {
        field.onBlur({
          target: { name: field.name, value: radioProps.value },
        });
      }}
    />
  );
};

const Radio: React.FC<FieldConfig & RadioProps> = ({ ...props }) => (
  <Field component={CustomRadio} {...props} />
);

export { Radio };
