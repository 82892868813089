import React, { FocusEventHandler, useCallback, useEffect } from 'react';
import {
  AutoSuggest as NCAutoSuggest,
  AutoSuggestProps,
  DependentValueProp,
  OptionProp,
} from 'dss-ui-library';
import objectPath from 'object-path';

import { Field, FieldProps } from 'formik';

const CustomAutoSuggest = ({
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
  onChange = () => null,
  ...textFieldProps
}: FieldProps & AutoSuggestProps) => {
  const errorMessage = objectPath.get(errors, field.name);
  const _touched = objectPath.get(touched, field.name);

  let selectedValue = field.value;
  if (field.value?.value === undefined) {
    selectedValue = {
      value: field.value,
      label: field.value,
    };
  }

  useEffect(() => {
    setFieldTouched(field.name, false);
  }, []);

  const _onChange = useCallback(
    (option: OptionProp) => {
      field.onChange({
        target: { name: field.name, value: option.value },
      });
      setFieldValue(field.name, option.value);
      if (option.dependentValues?.length ?? 0 > 0) {
        (option.dependentValues as DependentValueProp[]).forEach(
          (dependentValue) => {
            setFieldValue(dependentValue.field, dependentValue.value);
          },
        );
      }
      onChange(option);
    },
    [onChange, setFieldValue],
  );

  const _onBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFieldTouched(field.name, true);

      if (!e.target || typeof e.target.value != 'string') return;

      // if trimmed value differs from original value then update formik state
      const trimmedValue = e.target.value.trim();
      if (trimmedValue && trimmedValue !== e.target.value) {
        setFieldValue(field.name, trimmedValue);
      }
    },
    [setFieldTouched, setFieldValue],
  );

  return (
    <div data-cs-mask>
      <NCAutoSuggest
        {...textFieldProps}
        debounce
        delay={1}
        value={selectedValue}
        name={field.name}
        hideLoadingMessage
        hideNooptionsMessage
        onChange={_onChange}
        onBlur={_onBlur}
        errorMessage={_touched && errorMessage ? errorMessage : undefined}
      />
    </div>
  );
};

const AutoSuggest: React.FC<AutoSuggestProps> = ({ ...props }) => (
  <Field component={CustomAutoSuggest} {...props} />
);

export { AutoSuggest };
